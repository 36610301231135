import firebase from "firebase";
const config = {
  apiKey: "AIzaSyDfZpKuIbpSMrGugNYdSlRVeBToThQ4wZg",
  authDomain: "pakk-271009.firebaseapp.com",
  databaseURL: "https://pakk-271009.firebaseio.com",
  projectId: "pakk-271009",
  storageBucket: "pakk-271009.appspot.com",
  messagingSenderId: "296781538622",
  appId: "1:296781538622:web:34b95c6e77bcf0b408f86f",
  measurementId: "G-QRYR2B97Y3"
};
firebase.initializeApp(config);
export const db = firebase.database();
