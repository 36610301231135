import { createStore, combineReducers } from 'redux';
import mainReducers from './reducers/reducers';

const rootReducer = combineReducers({
  reducers: mainReducers,
});

const configureStore = () => {
  return createStore(rootReducer);
}

export default configureStore;
