export const SET_SECRET = 'SET_SECRET';

export const SET_STEP = 'SET_STEP';

export const SET_PACK = 'SET_PACK';

export const SET_START = 'SET_START';
export const SET_STARTROOM = 'SET_STARTROOM';
export const SET_STARTPHONE = 'SET_STARTPHONE';

export const SET_FINISH = 'SET_FINISH';
export const SET_FINISHROOM = 'SET_FINISHROOM';
export const SET_FINISHPHONE = 'SET_FINISHPHONE';
