import React, { Component } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  NavLink
} from 'react-router-dom';
import { connect } from 'react-redux';
import { setStep } from '../actions/actions.js';
import { loadStripe } from "@stripe/stripe-js";
import { CardElement } from "@stripe/react-stripe-js";
import axios from 'axios';

import { db } from '../services/firebase';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

import '../App.css';

import logo from '../img/logo.png';
import facebook from '../img/facebook.png';
import instagram from '../img/instagram.png';
import www from '../img/www.png'
import smallPack from '../img/sml.png';
import mediumPack from '../img/medium.png';
import bigPack from '../img/big.png';
import stripeLogo from '../img/stripe.png';


class CheckoutForm extends Component {

  componentDidMount() {
    axios({
      method: 'POST',
      url: 'https://us-central1-pakk-271009.cloudfunctions.net/paymentIntents',
      data: {
        amount: this.packList[this.props.pack-1].amount,
        currency: 'eur',
      },
    }).then(response => {
      this.setState({clientSecret: response.data.client_secret})
    }).catch(error => {
      this.setState({error: error})
    })
  }

  state = {
    isLoading: true,
    deliveryChecked: false,
    deliveryPrice: 0.00,
    price: 0.00,
    count: 1,
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
    expMonth: '09',
    expYear: '33',
    clientSecret: '',
    mailError: '',
    mail: '',
    makingPayment: false,
    completed: false
  }

  rendersteps = () => {

    let steps = []
    for(let i=1; i<4; i++) {
      steps.push(
        <div>
          <div class={this.state.step == i ? 'step' : 'stepActive'}>
            <text style={{color: this.state.step == i ? '#FFFFFF' : '#2424d2'}}>{i}</text>
          </div>
        </div>
      )
    }
    return steps
  }

  headers = () => {
    let step = this.state.step
    if(step == 1) {
      return "Vali paki suurus"
    }
    if(step == 2) {
      return "Kontaktandmed"
    }
    if(step == 3) {
      return "Tellimuse kinnitamine"
    }
  }

  packList = [
    {image: smallPack, heading: 'Väike', dimensions: '40 x 40 x 60 cm', price: '4.99', amount: 499},
    {image: mediumPack, heading: 'Keskmine', dimensions: '60 x 40 x 60 cm', price: '5.99', amount: 599},
    {image: bigPack, heading: 'Suur', dimensions: '80 x 40 x 60 cm', price: '6.99', amount: 699},
  ]

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  }

  checkEmail() {
    var pattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      if (!pattern.test(this.state.email)) {
        this.setState({error: "E-Posti aadress ei ole korrektne"})
      } else {
        this.handleSubmit()
      }
  }

  formatDate(value) {
    let dayNumb = ''
    let monthNumb = ''
    let formatDate = ''
    let formattedDate = ''
    let day = value.getDate()
    let month = value.getMonth()+1
    if(day < 10) {
      dayNumb = '0' + day
    } else {
      dayNumb = day
    }
    if(month < 10) {
      monthNumb = '0' + month
    } else {
      monthNumb = month
    }
    return formattedDate = dayNumb + "/" + monthNumb + "/" + value.getFullYear()
  }

  formatTime(value) {
    let hours = ''
    let minutes = ''
    let hour = value.getHours()
    let minute = value.getMinutes()
    if(hour < 10) {
      hours = '0' + hour
    } else {
      hours = hour
    }
    if(minute < 10) {
      minutes = '0' + minute
    } else {
      minutes = minute
    }
    return hours + ':' + minutes
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });

  }

  setMail(mail) {
    var pattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    if (!pattern.test(mail)) {
      // this.setState({mailError: "E-Posti aadress ei ole korrektne"})
      console.log('error')
    } else {
      this.setState({mail: mail})
    }
  }

  handleSubmit = async (event) => {

    this.setState({makingPayment: true})

    event.preventDefault();

    const {stripe, elements} = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);


    const payload = await stripe.confirmCardPayment(this.state.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });

    if (payload.error) {
      console.log('error: Payment failed ' + payload.error.message);
      this.setState({makingPayment: false, error: 'Maksmine ebaõnnestus: ' + payload.error.message})

    } else {
      const order = Number(new Date().getTime() + Math.floor(Math.random() * 10000) + 9999)
      axios({
        method: 'POST',
        url: 'https://us-central1-pakk-271009.cloudfunctions.net/sendGun',
        data: {
          total: this.packList[this.props.pack-1].price,
          start: this.props.start,
          startRoom: this.props.startRoom,
          startPhone: this.props.startPhone,
          finish: this.props.finish,
          finishRoom: this.props.finishRoom,
          finishPhone: this.props.finishPhone,
          date: this.formatDate(new Date()),
          time: this.formatTime(new Date()),
          name: this.state.name,
          mail: this.state.mail,
          order: order,
          packtype: this.packList[this.props.pack-1].heading + '-PAKK'
        },
      }).then(response => {
        console.log(response)
        db.ref("smallPacks").child(order).set({
          total: this.packList[this.props.pack-1].price,
          start: this.props.start,
          startRoom: this.props.startRoom,
          startPhone: this.props.startPhone,
          finish: this.props.finish,
          finishRoom: this.props.finishRoom,
          finishPhone: this.props.finishPhone,
          date: this.formatDate(new Date()),
          time: this.formatTime(new Date()),
          name: this.state.name,
          mail: this.state.mail,
          order: order,
          packtype: this.packList[this.props.pack-1].heading + '-PAKK'
        });
        this.setState({completed: true})
      }).catch(error => {
        this.setState({makingPayment: false})
        this.setState({error: 'Arve saatmine ebaõnnestus!'})
      })
    }
  };

  render() {
    const {stripe} = this.props;
    if(this.state.completed) {
      return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div class='confirmationBox'>
            <text class='boldText'>Tellimuse kokkuvõte</text>
              <div style={{marginTop: 50, textAlign: 'center'}}>
                <text>Tellimus on kinnitatud. Teie e-postile ({this.state.mail}) laekub peatselt kinnitus tehtud tellimusest.</text>
              </div>
          </div>
          <div class='confirmationBox'>
            <text class='boldText'>Tellimuse kokkuvõte</text>
              <div class='packageContainer'>
                <img src={this.packList[this.props.pack-1].image} />
                  <div class='packageTitle'>
                    <div class='boldText'></div>
                    <div class='packageDescription'>{this.packList[this.props.pack-1].dimensions}<br/>Max 30 kg</div>
                  </div>
                  <div class='price'>{this.packList[this.props.pack-1].price} €</div>
              </div>
              <div class='confimationRow'>
                <div class='pickup'>
                  <text style={{color: '#AAAAAA', fontSize: 12, marginRight: 5, marginTop: 10}}>Pealekorjamine</text>
                  <text style={{color: '#111111', fontSize: 12}}>{this.props.start}</text>
                  {this.props.startRoom.length > 0 && <text style={{color: '#111111', fontSize: 12}}>Ruum: {this.props.startRoom}</text> }
                  <text style={{color: '#111111', fontSize: 12}}>{this.props.startPhone}</text>
                </div>
                <div class='dropoff'>
                  <text style={{color: '#AAAAAA', fontSize: 12, marginRight: 5, marginTop: 10}}>Mahalaadimine</text>
                  <text style={{color: '#111111', fontSize: 12}}>{this.props.finish}</text>
                  {this.props.finishRoom.length > 0 && <text style={{color: '#111111', fontSize: 12}}>Ruum: {this.props.finishRoom}</text> }
                  <text style={{color: '#111111', fontSize: 12}}>{this.props.finishPhone}</text>
                </div>
              </div>
          </div>

        </div>
      )
    }
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div class='confirmationBox'>
          <text class='boldText'>Tellimuse kokkuvõte</text>
            <div class='packageContainer'>
              <img src={this.packList[this.props.pack-1].image} />
                <div class='packageTitle'>
                  <div class='boldText'></div>
                  <div class='packageDescription'>{this.packList[this.props.pack-1].dimensions}<br/>Max 30 kg</div>
                </div>
                <div class='price'>{this.packList[this.props.pack-1].price} €</div>
            </div>
            <div class='confimationRow'>
              <div class='pickup'>
                <text style={{color: '#AAAAAA', fontSize: 12, marginRight: 5, marginTop: 10}}>Pealekorjamine</text>
                <text style={{color: '#111111', fontSize: 12}}>{this.props.start}</text>
                {this.props.startRoom.length > 0 && <text style={{color: '#111111', fontSize: 12}}>Ruum: {this.props.startRoom}</text> }
                <text style={{color: '#111111', fontSize: 12}}>{this.props.startPhone}</text>
              </div>
              <div class='dropoff'>
                <text style={{color: '#AAAAAA', fontSize: 12, marginRight: 5, marginTop: 10}}>Mahalaadimine</text>
                <text style={{color: '#111111', fontSize: 12}}>{this.props.finish}</text>
                {this.props.finishRoom.length > 0 && <text style={{color: '#111111', fontSize: 12}}>Ruum: {this.props.finishRoom}</text> }
                <text style={{color: '#111111', fontSize: 12}}>{this.props.finishPhone}</text>
              </div>
            </div>
        </div>
        <hr/>
        <div class='infoBox'>
          <text style={{fontSize: 12}}>Tellimuse kinnitamiseks tuleb tasuda ettemaks. Pärast tellimuse kinnitamist saadame tellimuse kinnituse e-postile. </text>
        </div>
        <div class='addressInput'>
          <text class='boldText'>Ees-ja perekonnanimi</text>
          <input name='start' defaultValue={this.state.name} onChange={(event) => this.setState({name: event.target.value})}/>
        </div>
        <div class='addressInput'>
          <text class='boldText' style={{color: this.state.mail == '' ? 'red' : 'black'}}>E-Posti aadress</text>
          <input name='start' defaultValue={this.state.mail} onChange={(event) => this.setMail(event.target.value)}/>
        </div>
        <div class='addressInput'>
          <text class='boldText'>Maksekaardi andmed</text>
        </div>
        <div class='paymentArea'>

          <form onSubmit={this.handleSubmit}>
            <div className='cardInfo'>
            <CardElement
              hidePostalCode={true}
              options={{
                style: {

                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
            </div>
            <text style={{color: 'red'}}>{this.state.error}</text>
            <div>
              {this.state.makingPayment ?
                <div class='buttonRow'>
                <Loader
                   type="Bars"
                   color="#2424d2"
                   height={30}
                   width={30}
                />
                <text style={{color: '#2424d2', marginLeft: 5}}>Makset teostatakse. Palun oota..</text>
                </div>
              :
                <div class='buttonRow'>
                  <button style={{display: this.props.step == 1 ? 'none' : 'flex'}} className='buttonBack' onClick={() => this.props.setStep(this.props.step - 1)}>Tagasi</button>
                  {this.state.clientSecret == '' ?
                    <div className='buttonNext'>Oota..</div>
                  :
                    this.state.mail == '' ?
                    <div><text style={{color: '#2424d2', marginLeft: 5}}>Sisesta e-post</text></div>
                    :
                    <button className='buttonNext' onClick={() => this.handleSubmit} disabled={!stripe}>Kinnita</button>
                  }
                </div>
              }
            </div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
              <a href='https://stripe.com/payment-terms/legal' target='_blank'>
                <img src={stripeLogo} style={{height: 20}}/>
              </a>
              <text style={{marginLeft: 5, fontSize: 10, width: '80%', color: '#AAAAAA'}}>Krediitkaardimaksete vahendamine toimub Stripe Payments Europe, Ltd kaudu. Krediitkaardi andmeid ei salvestata järgnevateks tehinguteks.</text>
            </div>
          </form>

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    step: state.reducers.step,
    pack: state.reducers.pack,
    start: state.reducers.start,
    startRoom: state.reducers.startRoom,
    startPhone: state.reducers.startPhone,
    finish: state.reducers.finish,
    finishRoom: state.reducers.finishRoom,
    finishPhone: state.reducers.finishPhone,
    finishPhone: state.reducers.finishPhone
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setStep: (step) => {
      dispatch(setStep(step))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
