import React, { Component } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  NavLink
} from 'react-router-dom';
import { connect } from 'react-redux';
import { setSecret, setPack, setStep, setStart, setStartRoom, setStartPhone, setFinish, setFinishRoom, setFinishPhone } from '../actions/actions.js';

import '../App.css';

import Header from '../components/header';
import Contacts from './contacts';
import Confirmation from './confirmation';

import logo from '../img/logo.png';
import facebook from '../img/facebook.png';
import instagram from '../img/instagram.png';
import www from '../img/www.png'
import smallPack from '../img/sml.png';
import mediumPack from '../img/medium.png';
import bigPack from '../img/big.png';

class ViewItem extends Component {

  state = {
    error: ''
  }

  packList = [
    {image: smallPack, heading: 'Väike', dimensions: '40 x 40 x 60 cm', price: '4.99'},
    {image: mediumPack, heading: 'Keskmine', dimensions: '60 x 40 x 60 cm', price: '5.99'},
    {image: bigPack, heading: 'Suur', dimensions: '80 x 40 x 60 cm', price: '6.99'},
  ]

  renderPacks = () => {
    let packs = []
    for(let i=1; i<4; i++) {
      packs.push(
        <button className={this.props.pack == i ? 'packageContainer_selected' : 'packageContainer'} onClick={() => {this.props.setPack(i)}}>
          <img src={this.packList[i-1].image} />
            <div class='packageTitle'>
              <div class='boldText'>{this.packList[i-1].heading}-PAKK</div>
              <div class='packageDescription'>{this.packList[i-1].dimensions} <br/>Max 30 kg</div>
            </div>
            <div class='price'>{this.packList[i-1].price} €</div>
        </button>
      )
    }
    return packs
  }

  renderText() {
    return (
      <div className='infoBox'>
        <text style={{fontSize: 13, fontWeight: 'bold'}}>Tingimused</text>
        <text style={{fontSize: 12}}>Väikepaki saatmiseks tuleb... </text>
        <text style={{fontSize: 12}}>... valida paki maksimaalne suurus ja kaal.</text>
        <text style={{fontSize: 12}}>... sisestada saatja ning saaja kontaktandmed.</text>
        <text style={{fontSize: 12}}>... tasuda saadetise eest tellimuse kinnitamisel.</text>
        <text style={{fontSize: 12}}>Pärast tellimuse kinnitamist saadetakse teile kviitung e-posti aadressile.</text>
        <text style={{fontSize: 12}}>Kuller võtab teiega ühendust ning lepib kokku saadetise pealekorjamise aja. </text>

      </div>
    )
  }

  validateNext() {
    if(this.props.step == 2) {
      if(
        (this.props.start.toLowerCase().includes("tartu") || this.props.start.toLowerCase().includes("tallinn")) &&
        (this.props.finish.toLowerCase().includes("tartu") || this.props.finish.toLowerCase().includes("tallinn")) &&
        this.props.startPhone.length > 5 &&
        this.props.finishPhone.length > 5
      ) {
        this.setState({error: ''})
        this.props.setStep(this.props.step + 1)
      } else {
        this.setState({error: 'Teenus peab algama või lõppema Tallinnas või Tartus ning saatja ja saaja telefoninumbrid peavad olema märgitud'})
      }
    } else {
      this.setState({error: ''})
      this.props.setStep(this.props.step + 1)
    }
  }

  render() {
    return (
      <div class='container'>
        <div class='front-header'>
          <img src={logo} style={{width: 140}}/>
        </div>

        <div class="yellowBox">
          <text class="yellowBoxText">Hetkel toimub väikepakivedu ainult Tartu - Tallinn - Tartu marsruudil</text>
        </div>

        <div class="mainArea">

          <Header />

          <div style={{display: 'flex', flexDirection: 'column'}}>

            {this.props.step == 1 && this.renderText()}
            {this.props.step == 1 && this.renderPacks()}
            {this.props.step == 2 && <Contacts />}
            {this.props.step == 3 && <Confirmation />}

          </div>
          <text>{this.state.error}</text>
          <div class='buttonRow'>
            <button style={{display: this.props.step == 2 ? 'flex' : 'none'}} className='buttonBack' onClick={() => this.props.setStep(this.props.step - 1)}>Tagasi</button>
            <button style={{display: this.props.step == 3 || this.props.pack == '' ? 'none' : 'flex'}} className='buttonNext' onClick={() => this.validateNext()}>Edasi</button>
          </div>
        </div>

        <div class="backImageContainer"></div>
        <div class="socialMedia">
          <a href="http://www.facebook.com/PAKK.eesti" target="_blank">
            <img src={facebook} class='socialImage'/>
          </a>
          <a href="https://www.instagram.com/pakk_eesti/" target="_blank">
            <img src={instagram} class='socialImage'/>
          </a>
          <a href="http://www.pakk-service.ee" target="_blank">
            <img src={www} class='socialImage'/>
          </a>
        </div>
        <div class='bottomContacts'>
          <text>OÜ PAKK Eesti | Reg.nr.: 14964196 | KMKR: EE102268818</text>
          <text>info@pakk-service.ee | +372 5226 818</text>
        </div>
      </div>
    );
  }

}

const mapStateToProps = state => {
  return {
    step: state.reducers.step,
    pack: state.reducers.pack,
    start: state.reducers.start,
    startRoom: state.reducers.startRoom,
    startPhone: state.reducers.startPhone,
    finish: state.reducers.finish,
    finishRoom: state.reducers.finishRoom,
    finishPhone: state.reducers.finishPhone
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSecret: (secret) => {
      dispatch(setSecret(secret))
    },
    setPack: (pack) => {
      dispatch(setPack(pack))
    },
    setStep: (step) => {
      dispatch(setStep(step))
    },
    setStart: (start) => {
      dispatch(setStart(start))
    },
    setStartRoom: (startRoom) => {
      dispatch(setStartRoom(startRoom))
    },
    setStartPhone: (startPhone) => {
      dispatch(setStartPhone(startPhone))
    },
    setFinish: (finish) => {
      dispatch(setFinish(finish))
    },
    setFinishRoom: (finishRoom) => {
      dispatch(setFinishRoom(finishRoom))
    },
    setFinishPhone: (finishPhone) => {
      dispatch(setFinishPhone(finishPhone))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewItem)
