import { SET_SECRET, SET_STEP, SET_PACK, SET_START, SET_STARTROOM, SET_STARTPHONE, SET_FINISH, SET_FINISHROOM, SET_FINISHPHONE } from './types';

export const setSecret = secret => {
  return {
    type: SET_SECRET,
    payload: secret
  }
}

export const setStep = step => {
  return {
    type: SET_STEP,
    payload: step
  }
}

export const setPack = pack => {
  return {
    type: SET_PACK,
    payload: pack
  }
}

export const setStart = start => {
  return {
    type: SET_START,
    payload: start
  }
}

export const setStartRoom = startRoom => {
  return {
    type: SET_STARTROOM,
    payload: startRoom
  }
}

export const setStartPhone = startPhone => {
  return {
    type: SET_STARTPHONE,
    payload: startPhone
  }
}

export const setFinish = finish => {
  return {
    type: SET_FINISH,
    payload: finish
  }
}

export const setFinishRoom = finishRoom => {
  return {
    type: SET_FINISHROOM,
    payload: finishRoom
  }
}

export const setFinishPhone = finishPhone => {
  return {
    type: SET_FINISHPHONE,
    payload: finishPhone
  }
}
