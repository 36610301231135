import React, { Component } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch
} from 'react-router-dom';
import Home from './pages/home';
import Contacts from './pages/contacts';
import Confirmation from './pages/confirmation';

import './App.css';

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/contacts" component={Contacts}></Route>
          <Route exact path="/confirmation" component={Confirmation}></Route>
        </Switch>
      </Router>
    )
  }
}

export default App;
