import { SET_SECRET, SET_STEP, SET_PACK, SET_START, SET_STARTROOM, SET_STARTPHONE, SET_FINISH, SET_FINISHROOM, SET_FINISHPHONE } from '../actions/types';

const initialState = {
  secret: '',
  step: 1,
  pack: '',
  start: '',
  startRoom: '',
  startPhone: '',
  finish: '',
  finishRoom: '',
  finishPhone: ''
}

const mainReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_SECRET:
      return {
        ...state,
        secret: action.payload
      }
    case SET_STEP:
      return {
        ...state,
        step: action.payload
      }
    case SET_PACK:
      return {
        ...state,
        pack: action.payload
      }
    case SET_START:
      return {
        ...state,
        start: action.payload
      }
    case SET_STARTROOM:
      return {
        ...state,
        startRoom: action.payload
      }
    case SET_STARTPHONE:
      return {
        ...state,
        startPhone: action.payload
      }
    case SET_FINISH:
      return {
        ...state,
        finish: action.payload
      }
    case SET_FINISHROOM:
      return {
        ...state,
        finishRoom: action.payload
      }
    case SET_FINISHPHONE:
      return {
        ...state,
        finishPhone: action.payload
      }
    default:
      return state;
  }
}

export default mainReducers
