import React, { Component } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  NavLink
} from 'react-router-dom';

import { connect } from 'react-redux';
import { setPack, setStep, setStart, setStartRoom, setStartPhone, setFinish, setFinishRoom, setFinishPhone} from '../actions/actions.js';

import '../App.css';

import logo from '../img/logo.png';
import facebook from '../img/facebook.png';
import instagram from '../img/instagram.png';
import www from '../img/www.png'

class Contacts extends Component {


  render() {
    return (

            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div class='infoBox'>
                <text style={{fontSize: 12}}>Kui tellimus on esitatud enne kella 16.30, siis toimub pealekorjamine samal päeval. Hilisemad tellimused korjatakse peale järgneval tööpäeval. Teiega võetakse eelnevalt ühendust ning lepitakse kokku sobiv aeg.</text>
              </div>
              <div class='addressBlock'>
                <div class='addressInput'>
                  <text class='boldText'>Pealekorjamise aadress</text>
                  <input name='start' defaultValue={this.props.start} onChange={(event) => this.props.setStart(event.target.value)}/>
                </div>
                <div class='apartmentInput'>
                  <text class='boldText'>Korter</text>
                  <input name='startRoom' defaultValue={this.props.startRoom} onChange={(event) => this.props.setStartRoom(event.target.value)}/>
                </div>
              </div>
              <div class='phoneRow'>
                <text class='boldText'>Saatja telefon:</text>
                <input name='startPhone'  defaultValue={this.props.startPhone} onChange={(event) => this.props.setStartPhone(event.target.value)}/>
              </div>
              <hr/>
              <div class='addressBlock'>
                <div class='addressInput'>
                  <text class='boldText'>Mahalaadimise aadress</text>
                  <input name='finish' defaultValue={this.props.finish} onChange={(event) => this.props.setFinish(event.target.value)}/>
                </div>
                <div class='apartmentInput'>
                  <text class='boldText'>Korter</text>
                  <input name='finishRoom' defaultValue={this.props.finishRoom} onChange={(event) => this.props.setFinishRoom(event.target.value)}/>
                </div>
              </div>
              <div class='phoneRow'>
                <text class='boldText'>Saaja telefon:</text>
                <input name='finishPhone' defaultValue={this.props.finishPhone} onChange={(event) => this.props.setFinishPhone(event.target.value)}/>
              </div>
              <div class='error'>
                <text style={{fontSize: 12, color: 'red'}}>{this.props.errorCity}</text>
              </div>
            </div>

    );
  }
}


const mapStateToProps = state => {
  return {
    step: state.reducers.step,
    pack: state.reducers.pack,
    start: state.reducers.start,
    startRoom: state.reducers.startRoom,
    startPhone: state.reducers.startPhone,
    finish: state.reducers.finish,
    finishRoom: state.reducers.finishRoom,
    finishPhone: state.reducers.finishPhone
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setPack: (pack) => {
      dispatch(setPack(pack))
    },
    setStep: (step) => {
      dispatch(setStep(step))
    },
    setStart: (start) => {
      dispatch(setStart(start))
    },
    setStartRoom: (startRoom) => {
      dispatch(setStartRoom(startRoom))
    },
    setStartPhone: (startPhone) => {
      dispatch(setStartPhone(startPhone))
    },
    setFinish: (finish) => {
      dispatch(setFinish(finish))
    },
    setFinishRoom: (finishRoom) => {
      dispatch(setFinishRoom(finishRoom))
    },
    setFinishPhone: (finishPhone) => {
      dispatch(setFinishPhone(finishPhone))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contacts)
