import React, { Component } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  NavLink
} from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import CheckoutForm from '../components/checkoutForm'

const InjectedCheckoutForm = () => {
  return (
    <ElementsConsumer>
      {({elements, stripe}) => (
        <CheckoutForm elements={elements} stripe={stripe} />
      )}
    </ElementsConsumer>
  );
};

const stripePromise = loadStripe('pk_test_8npaRZKJrXst7wIWPcfCeOsr00HrpoOOWU');

const Confirmation = () => {

  return (
    <Elements stripe={stripePromise}>
      <InjectedCheckoutForm />
    </Elements>
  );
};

export default Confirmation;
