import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../App.css';

class Header extends Component {

  headers = () => {
    let step = this.props.step
    if(step == 1) {
      return "Vali paki suurus"
    }
    if(step == 2) {
      return "Kontaktandmed"
    }
    if(step == 3) {
      return "Tellimuse kinnitamine"
    }
  }

  rendersteps = () => {

    let steps = []
    for(let i=1; i<4; i++) {
      steps.push(
        <div>
          <div class={this.props.step == i ? 'step' : 'stepActive'}>
            <text style={{color: this.props.step == i ? '#FFFFFF' : '#2424d2'}}>{i}</text>
          </div>
        </div>
      )
    }
    return steps
  }

  render() {
    return (
      <div class="stepHeader">
        <text class='stepHeading'>{this.headers()}</text>
        <div class='steps'>
          {this.rendersteps()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    step: state.reducers.step
  }
}

export default connect(mapStateToProps, null)(Header)
